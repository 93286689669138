import {
  useEffect, useMemo, useState, useCallback,
} from 'react';
import { useRouter as PagesRouter } from 'next/compat/router';
import { useExtendedRouter } from '@hooks/next-routing-wrappers';
import { useTranslation } from 'next-i18next';
import { useAuthClient } from '@hooks/common/useAuthClient/useAuthClient';
import {
  useSwrJobAddToBlocked,
  useSwrJobAddToFavorites,
  useSwrJobApply,
  useSwrJobRemoveFromBlocked,
  useSwrJobRemoveFromFavorites,
} from '@hooks/useSwrApi/endpoints/job';
import { useSwrBulkApplyFeed } from '@hooks/useSwrApi/endpoints/job-operations';
import { JobListType, JobPlacement } from '@type/job-params';
import { sendDataLayerEvent } from '@utils/send-data-layer-event/send-data-layer-event';
import { EasyApplyModal } from '@components/templates/job/apply/EasyApplyModal';
import { PersonalDataModal } from '@components/templates/job/apply/PersonalDataModal';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { setCookie } from 'cookies-next';
import { getI18nLinkHref } from '@services/i18nPaths';
import { useHeaderInfoData } from '@components/layout/Header/MainNavbar/data/useHeaderInfoData';
import { updateBulkApplyFeed } from '@store/reducers/jobReducer';
import { useAppDispatch } from '@store/index';
import { InterViewQuestionsModal } from '@components/templates/job/apply/InterviewQuestionsModal';
import { JobQuestion } from '@type/v1-api-types';
import { JOB_APPLY_COOKIE_NAME, JOB_APPLY_WEB_TYPE } from '../../constants';

interface JobTalentActionsHookProps {
  jobSlug: string;
  likedProp?: boolean;
  dislikedProp?: boolean;
  appliedProp: boolean;
  jobId?: string;
  jobTitle?: string;
  company?: string;
  applyCallback?: () => void;
  requestId?: string;
  position?: number;
  placement?: JobPlacement;
  listType?: JobListType;
  hasOwnApplyUrl?: boolean;
  withEasyApply?: boolean;
  jobQuestions?: JobQuestion[],
  interviewModalTrigger?: () => void;
}

/**
 * @description Job Talent Actions hook. Used for actions that can be triggered by a talent on a job:
 * add to favorites, remove from favorites, add to blocked, remove from blocked, apply to job
 * Can be used in job listing page (cards) or on job detail page.
 *
 * Props:
 * - **jobSlug** - the job slug.
 * - **likedProp** - whether the job is liked or not (the value that comes from API).
 * - **dislikedProp** - whether the job is blocked or not (the value that comes from API).
 * - **appliedProp** - whether the current user has applied to this job (the value that comes from API).
 * - **jobId** - the job ID.
 * - **jobTitle** - the job title.
 * - **company** - the employer name.
 * - **applyCallback** - optional, callback function to be called after apply.
 * - **requestId** - optional, tracking request id. Used for tracking.
 * - **position** - optional, the job position in list. Used for tracking.
 * - **placement** - optional, the job placement (card list / detail page). Used for tracking.
 * - **listType**: optional, the job list type. Used for tracking.
 * - **hasOwnApplyUrl**: optional, whether the job has own apply URL.
 * - **withEasyApply**: optional, whether the Easy Apply functionality is enabled.
 * - **jobQuestions**: optional, the job questions.
 * - **isSinglePage**: optional, whether the job is displayed on a single page.
 *
 * Returns:
 * - **isFavorite** - whether the job is favorite or not (the value from state).
 * - **isBlocked** - whether the job is blocked or not (the value from state).
 * - **isLoadingFavoritesAction** - a boolean flag indicating whether the add / remove job from favorites action is in progress.
 * - **isLoadingBlockedAction** - a boolean flag indicating whether the add / remove job from blocked action is in progress.
 * - **isApplying** - a boolean flag indicating whether the apply action is in progress.
 * - **hasApplied** - a boolean flag indicating that the user has successfully applied.
 * - **addToFavorites** - the function to call to add a job to favorites.
 * - **removeFromFavorites** - the function to call to remove a job from favorites.
 * - **addToBlocked** - the function to call to add a job to blocked.
 * - **removeFromBlocked** - the function to call to remove a job from blocked.
 * - **applyToJob** - the function to call to apply to a job.
 *
 */

export const useJobTalentActions = (props: JobTalentActionsHookProps) => {
  // Destructure props
  const {
    jobSlug, jobId, likedProp, dislikedProp, appliedProp, applyCallback: customApplyCallback,
    requestId, position, placement, listType, jobTitle, company, hasOwnApplyUrl, withEasyApply,
    jobQuestions, interviewModalTrigger,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // NextJs Router
  const { router, locale, params } = useExtendedRouter();
  const pagesRouter = PagesRouter();

  // Authentication client side
  const { isAuth, redirectToLogin } = useAuthClient();

  // Redux dispatch
  const appDispatch = useAppDispatch();

  // Header info data
  const { forceCheckAuthCookie } = useHeaderInfoData(locale, false);

  // Easy Apply Modal
  const easyApplyModal = useModal(EasyApplyModal);

  let applyToJob: () => void;

  // State
  // ******************************************************
  const [isFavorite, setIsFavorite] = useState(likedProp);
  const [isBlocked, setIsBlocked] = useState(dislikedProp);
  const [hasApplied, setHasApplied] = useState(appliedProp);

  // A flag to indicate whether to start fetching the bulk apply feed
  // It is set to true after every successful application
  const [shouldFetchBulkApplyFeed, setShouldFetchBulkApplyFeed] = useState(false);

  // A flag to indicate whether to show personal data modal
  const [showPersonalDataModal, setShowPersonalDataModal] = useState(false);

  // Sync state when props are updated
  // ******************************************************
  useEffect(() => {
    setIsFavorite(likedProp);
    setIsBlocked(dislikedProp);
    setHasApplied(appliedProp);
    setShouldFetchBulkApplyFeed(false);
  }, [jobSlug, likedProp, dislikedProp, appliedProp]);

  // Callback functions
  // ******************************************************

  // Added to favorites callback
  const addedToFavoritesCallback = () => {
    setIsFavorite(true);

    // When the job is added to favorites, it is also removed from blocked.
    setIsBlocked(false);
  };

  // Removed from favorites callback
  const removedFromFavoritesCallback = () => {
    setIsFavorite(false);
  };

  // Added to blocked callback
  const addedToBlockedCallback = () => {
    setIsBlocked(true);

    // When the job is blocked, it is also removed from favorites.
    setIsFavorite(false);
  };

  // Removed from favorites callback
  const removedFromBlockedCallback = () => {
    setIsBlocked(false);
  };

  // Apply callback functions
  // ******************************************************
  const applyCallback = () => {
    // Update internal state
    setHasApplied(true);

    // Send Events to Data Layer - Fast apply from card
    if (placement === JobPlacement.CARD) {
      sendDataLayerEvent({
        event: 'job_apply_event',
        googleMeasurementEvents: {
          job_apply: {
            item_id: jobId,
            item_name: jobTitle,
            item_brand: company,
            placement: 'from_list',
            item_list_id: requestId,
            item_list_name: 'search',
          },
        },
      });
    } else {
      // Send Event to Data Layer
      sendDataLayerEvent({
        event: 'job_apply_event',
        googleMeasurementEvents: {
          job_apply: {
            item_id: jobId,
            item_name: jobTitle,
            item_brand: company,
            placement,
            item_list_id: requestId,
          },
        },
      });
    }

    // If job has own apply URL, redirect to external application redirect page
    if (hasOwnApplyUrl) {
      // we're not redirecting inside React, so no router.push
      window.location.href = `/${locale}/job/${jobSlug}/external-application-redirect`;
    } else if (jobQuestions?.length) {
      // If the job has questions, show the Interview Questions Modal
      if (interviewModalTrigger) {
        interviewModalTrigger();
      } else {
        const callbackFn = () => {
          setShouldFetchBulkApplyFeed(true);
        };
        void NiceModal.show(InterViewQuestionsModal, {
          jobQuestion: jobQuestions, locale, onSaveSuccess: callbackFn, jobSlug,
        });
      }
    } else {
      // start load the bulk apply feed
      setShouldFetchBulkApplyFeed(true);
    }
  };

  // SWR
  // ******************************************************

  // Add job to favorites
  const {
    trigger: addToFavoritesTrigger,
    isMutating: isAddingToFavorites,
  } = useSwrJobAddToFavorites(jobSlug, locale, addedToFavoritesCallback, t('job.liked.message'));

  // Remove job from favorites
  const {
    trigger: removeFromFavoritesTrigger,
    isMutating: isRemovingFromFavorites,
  } = useSwrJobRemoveFromFavorites(jobSlug, locale, removedFromFavoritesCallback, t('job.unliked.message'));

  // Add job to blocked
  const {
    trigger: addToBlockedTrigger,
    isMutating: isAddingToBlocked,
  } = useSwrJobAddToBlocked(jobSlug, locale, addedToBlockedCallback, t('job.disliked.message'));

  // Remove job from blocked
  const {
    trigger: removeFromBlockedTrigger,
    isMutating: isRemovingFromBlocked,
  } = useSwrJobRemoveFromBlocked(jobSlug, locale, removedFromBlockedCallback, t('job.restored.message'));

  // Apply to job
  const {
    trigger: applyTrigger,
    isMutating: isApplying,
  } = useSwrJobApply({
    slug: jobSlug,
    locale,
    successCallback: () => {
      applyCallback();

      if (customApplyCallback) {
        customApplyCallback();
      }
    },
    errorCallback: (errorObj) => {
      if (!errorObj || !errorObj.errors?.length) {
        return;
      }

      const errorCode = errorObj.errors[0].code;

      if (errorCode === 452 || errorCode === 453) {
        void NiceModal.show(PersonalDataModal, { locale, onUpdateSuccess: () => applyToJob() });
      }
    },
    toastsOptions: {
      successToastText: t('job.apply.react.toast.success'),
    },
  });

  // Helpers
  // ******************************************************
  const isLoadingFavoritesAction = useMemo(
    () => isAddingToFavorites || isRemovingFromFavorites,
    [isAddingToFavorites, isRemovingFromFavorites],
  );

  const isLoadingBlockedAction = useMemo(
    () => isAddingToBlocked || isRemovingFromBlocked,
    [isAddingToBlocked, isRemovingFromBlocked],
  );

  // Add to favorites function
  // ******************************************************
  const addToFavorites = () => {
    if (isAuth) {
      void addToFavoritesTrigger();
    } else {
      redirectToLogin();
    }
  };

  // Remove from favorites function
  // ******************************************************
  const removeFromFavorites = () => {
    if (isAuth) {
      void removeFromFavoritesTrigger();
    } else {
      redirectToLogin();
    }
  };

  // Add to blocked function
  // ******************************************************
  const addToBlocked = () => {
    if (isAuth) {
      void addToBlockedTrigger();
    } else {
      redirectToLogin();
    }
  };

  // Remove from blocked function
  // ******************************************************
  const removeFromBlocked = () => {
    if (isAuth) {
      void removeFromBlockedTrigger();
    } else {
      redirectToLogin();
    }
  };

  // Fast apply to job function
  // ******************************************************
  const fastApply = useCallback(() => {
    void applyTrigger({
      requestId,
      pos: position,
      list: listType,
      placement,
      appType: JOB_APPLY_WEB_TYPE,
    });
  }, [applyTrigger, requestId, position, listType, placement]);

  // Apply to job function
  // ******************************************************
  applyToJob = () => {
    if (isAuth) {
      fastApply();
    } else if (withEasyApply) {
      // Open Easy Apply Modal
      easyApplyModal.show({ jobSlug }).then((response) => {
        if (typeof response === 'number' && response) {
          // The API response will set RM_COOKIE, so we need to re-check it.
          // If the cookie is found, the header info data will be updated.
          forceCheckAuthCookie();

          if (response === 200) {
            setHasApplied(true);

            // Go the after apply page
            void router.push(`/job/${jobSlug}/after-apply`);
          } else if (response === 452 || response === 453) {
            setShowPersonalDataModal(true);
          }
        }
      }).catch(() => {});
    } else {
      // Set cookie for job-apply. By default, it is a session cookie
      setCookie(JOB_APPLY_COOKIE_NAME, JSON.stringify({ jobSlug }));

      // Redirect to login page
      redirectToLogin();
    }
  };

  // SWR - Load jobs for bulk apply
  // ***********************************************
  const { data: bulkApplyFeed } = useSwrBulkApplyFeed({
    shouldFetch: shouldFetchBulkApplyFeed,
  });

  // Save the bulk apply feed in Redux (to be displayed in Bulk Apply Modal), or redirect to after apply page
  // ***********************************************
  useEffect(() => {
    if (bulkApplyFeed && shouldFetchBulkApplyFeed) {
      if (bulkApplyFeed.items?.length) {
        appDispatch(updateBulkApplyFeed([...bulkApplyFeed.items]));
      } else {
        void router.push(`/job/${jobSlug}/after-apply`);
      }

      setShouldFetchBulkApplyFeed(false);
    }
  }, [bulkApplyFeed, jobSlug, router, shouldFetchBulkApplyFeed, appDispatch]);

  // Check if `personalDataModal` query param is set.
  // If true, display the PersonalDataModal.
  // Note that the query param is then removed from URL.
  // ******************************************************
  useEffect(() => {
    if (!pagesRouter) {
      return;
    }

    const { personalDataModal, jobSlug: jobSlugParam, ...otherQueryParams } = pagesRouter?.query || {};

    if (typeof personalDataModal === 'string') {
      setShowPersonalDataModal(true);
      void pagesRouter.replace({ query: { ...otherQueryParams, jobSlug: jobSlugParam } }, { pathname: getI18nLinkHref(`/job/${jobSlug}`, locale), query: otherQueryParams }, { shallow: true });
    }
  }, [pagesRouter, jobSlug, locale, params]);

  useEffect(() => {
    if (showPersonalDataModal) {
      void NiceModal.show(PersonalDataModal, { locale, onUpdateSuccess: fastApply });
    }
  }, [locale, showPersonalDataModal, fastApply]);

  // Return
  // ***********************************************
  return {
    isFavorite,
    isBlocked,
    isLoadingFavoritesAction,
    isLoadingBlockedAction,
    isApplying,
    hasApplied,
    addToFavorites,
    removeFromFavorites,
    addToBlocked,
    removeFromBlocked,
    applyToJob,
    setShouldFetchBulkApplyFeed,
  };
};
